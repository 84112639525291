/* src/App.css */
* {
  margin: 0;
  padding: 0;
}
.App {
  background-color: #e5ddd5;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
}

.chat-container {
  width: 50%;
  height: 80%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  background-color: white;
}

.chat-header {
  background-color: #075E54;
  color: white;
  padding: 10px;
  text-align: center;
}

.chat-box {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  background-color: #e5ddd5;
}

.chat-message {
  margin: 5px;
  padding: 8px;
  border-radius: 7px;
  max-width: 70%;
  word-wrap: break-word;
}

.chat-message.user {
  background-color: #DCF8C6;
  align-self: flex-end;
}

.chat-message.bot {
  background-color: #ffffff;
  align-self: flex-start;
}

.message-input {
  display: flex;
  padding: 10px;
  background-color: #f0f0f0;
  align-items: center;
}

input {
  flex: 1;
  padding: 10px;
  border: none;
  border-radius: 20px;
  margin-right: 10px;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  background-color: #075E54;
  color: white;
  cursor: pointer;
}

button:hover {
  background-color: #128C7E;
}

.structured-message {
  background: #f4f4f8;
  padding: 10px;
  border-radius: 10px;
  margin: 5px 0;
}

.section h4 {
  font-size: 16px;
  color: #333;
}

.section p {
  font-size: 14px;
  margin-left: 20px;
}

.chat-header {
  padding: 20px 20px;
  text-align: center;
  position: relative;
}

.clear-button {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #b32f3c;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.clear-button:hover {
  background-color: #c82333;
}


@media screen and (max-width: 768px) {
  .chat-container {
    width: 80%;
    height: 80%;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    background-color: white;
  }
}

@media screen and (max-width: 425px) {
  .chat-container {
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    background-color: white;
  }
}
